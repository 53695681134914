import React from "react";
import {useTranslation} from "react-i18next";
import {translationHelper} from "../../helpers/translation.helper";
import NavigationItem from "./navigation-item.component";
import {AppState} from "../../store/reducers";
import {connect} from "react-redux";
import {Link} from "gatsby";
import {PageInterface} from "../../interfaces/page.interface";
import {useConfig} from "../../hooks/use-config.hook";
import {setCartStep} from "../../store/actions/cart.action";
import {CartStep} from "../../interfaces/cart-step";
import {MenuIcon, ShoppingBagIcon, XIcon} from "@heroicons/react/solid";
import {classNames} from "@eventiofi/eventio-commons-ui";
import {TranslationKeys} from "../../interfaces/translation-keys.enum";
import LanguageSelector from "./language-selector.component";

interface NavigationComponentProps {
    item_count: number;
    setCartStep: (step: CartStep) => void;
}

const Navigation: React.FC<NavigationComponentProps> = (props) => {
    const {setCartStep} = props;

    const [showMobileMenu, setShowMobileMenu] = React.useState(false);
    const {t, i18n} = useTranslation();

    const items = [] as any;

    const config = useConfig();

    // Add navigation items from pages & event folders
    const mapPages = () => {
        config.pages.forEach((page: PageInterface) => {
            const item = {
                title: translationHelper(page.title, i18n.language),
                pathname: page.path,
            };
            items.push(item);
        });
        config.event_folders
            .filter((folder) => folder.show_in_nav)
            .sort((folder) => folder.sort_order || 0)
            .forEach((folder) => {
                const item = {
                    title: translationHelper(folder.title, i18n.language),
                    pathname: `/${folder.slug}`,
                };
                return items.push(item);
            });
        config.product_folders
            .filter((folder) => folder.show_in_nav)
            .sort((folder) => folder.sort_order || 0)
            .forEach((folder) => {
                const item = {
                    title: translationHelper(folder.title, i18n.language),
                    pathname: `/${folder.slug}`,
                };
                items.push(item);
            });
    };

    mapPages();

    let navItems = items.map((item: any, i: number) => (
        <NavigationItem
            title={item.title}
            pathname={item.pathname}
            key={i}
            mobile={false}
        />
    ));

    let mobileNavItems = items.map((item: any, i: number) => (
        <NavigationItem
            title={item.title}
            pathname={item.pathname}
            key={i}
            mobile={true}
        />
    ));

    const styles: any = {};
    if (config.theme.colors.nav_color) {
        styles.backgroundColor = config.theme.colors.nav_color;
    }

    return (
        <nav className="bg-white shadow relative" style={styles}>
            <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex justify-between h-16">
                    <div className="hidden sm:flex">{navItems}</div>
                    <div className="hidden sm:ml-6 sm:flex sm:items-center space-x-4">
                        <LanguageSelector/>
                        {config.cart_enabled && (
                            <Link
                                onClick={() => setCartStep(CartStep.CUSTOMER_DETAILS)}
                                to={"/cart"}
                                className="btn btn-secondary flex items-center group"
                                aria-label={t(TranslationKeys.COMMON_PROCEED_TO_CART)}
                                title={t(TranslationKeys.COMMON_PROCEED_TO_CART)}
                            >
                                <ShoppingBagIcon
                                    className="flex-shrink-0 h-6 w-6 text-gray-400 group-hover:text-gray-500"
                                    aria-hidden="true"
                                />
                                <span
                                    className="ml-2 text-sm font-medium text-gray-700 group-hover:text-gray-800">{props.item_count}</span>
                                <span className="sr-only">{t(TranslationKeys.COMMON_PROCEED_TO_CART)}</span>
                            </Link>
                        )}
                    </div>
                    <div className="-mr-2 flex items-center justify-between w-full sm:hidden">
                        <button
                            className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                            aria-label="Main menu"
                            aria-expanded="false"
                            onClick={() => setShowMobileMenu(!showMobileMenu)}
                        >
                            <MenuIcon className={classNames(showMobileMenu ? "hidden" : "block", "h-6 w-6")}/>
                            <XIcon className={classNames(showMobileMenu ? "block" : "hidden", "h-6 w-6")}/>
                        </button>
                        <div className="flow-root lg:ml-8">
                            <Link to="/cart" className="group -m-2 p-2 flex items-center">
                                <ShoppingBagIcon
                                    className="flex-shrink-0 h-6 w-6 text-gray-400 group-hover:text-gray-500"
                                    aria-hidden="true"
                                />
                                <span
                                    className="ml-2 text-sm font-medium text-gray-700 group-hover:text-gray-800">{props.item_count}</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={(showMobileMenu ? "block" : "hidden") + " sm:hidden"}
            >
                <div className="pt-2 pb-3">
                    {mobileNavItems}
                    {config.cart_enabled && (
                        <NavigationItem
                            title={t(TranslationKeys.CART)}
                            pathname="/cart"
                            mobile={true}
                        />
                    )}
                </div>
            </div>
        </nav>
    );
};

const mapStateToProps = (state: AppState) => ({
    item_count: state.cart.items.reduce(
        (total, item) => total + item.amount,
        0
    ),
});

const mapDispatchToProps = (dispatch: any) => ({
    setCartStep: (step: CartStep) => dispatch(setCartStep(step)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
