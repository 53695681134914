export enum TranslationKeys {
    COMMON_SEARCH = "common.search",
    COMMON_CLOSE = "common.close",
    COMMON_OPEN = "common.open",
    COMMON_DELETE = "common.delete",
    COMMON_ALL = "common.all",
    COMMON_SELECT_LANGUAGE = "common.select_language",
    COMMON_FIRST_NAME = "common.first_name",
    COMMON_LAST_NAME = "common.last_name",
    COMMON_EMAIL = "common.email",
    COMMON_PHONE = "common.phone",
    COMMON_COMPANY_NAME = "common.company_name",
    COMMON_ADDRESS_LINE_FIRST = "common.address_line_first",
    COMMON_ADDRESS_LINE_SECOND = "common.address_line_second",
    COMMON_POSTAL_CODE = "common.postal_code",
    COMMON_CITY = "common.city",
    COMMON_COUNTRY = "common.country",
    COMMON_ADD_TO_CART = "common.add_to_cart",
    COMMON_ADDING_TO_CART = "common.adding_to_cart",
    COMMON_NOT_AVAILABLE = "common.not_available",
    COMMON_SELECT_ITEM_AMOUNT_TO_CONTINUE = "common.select_item_amount_to_continue",
    COMMON_SELECT_SEATS_TO_CONTINUE = "common.select_seats_to_continue",
    COMMON_PROCEED_TO_CART = "common.proceed_to_cart",
    COMMON_BACK_TO_SHOPPING = "common.back_to_shopping",
    COMMON_SELECT_AMOUNT = "common.select_amount",
    COMMON_EVENT_REGISTRATION = "common.event_registration",
    COMMON_CANNOT_EDIT_ITEM_AMOUNT = "common.cannot_edit_item_amount",
    COMMON_BACK_TO_EVENT = "common.back_to_event",
    COMMON_SEATING_CHART_SECTION = "common.seating_chart_section",
    COMMON_SEATING_CHART_ROW_NUMBER = "common.seating_chart_row_number",
    COMMON_SEATING_CHART_SEAT_NUMBER = "common.seating_chart_seat_number",

    COMMON_SELECT_PRODUCT = "common.select_product",

    COMMON_PRODUCT = "common.product",
    COMMON_PRICE = "common.price",
    COMMON_AMOUNT = "common.amount",

    COMMON_PRIVACY_POLICY = "common.privacy_policy",
    COMMON_COOKIE_POLICY = "common.cookie_policy",
    COMMON_TERMS_AND_CONDITIONS = "common.terms_and_conditions",
    COMMON_CONTACT = "common.contact",
    COMMON_WEBSERVICE_IN_TEST_MODE = "common.webservice_in_test_mode",
    COMMON_BACK_TO_HOME = "common.back_to_home",
    COMMON_ORGANIZER_NAME = "common.organizer_name",
    COMMON_ORGANIZER_BUSINESS_ID = "common.organizer_business_id",
    COMMON_CONTACT_TECHNICAL_SUPPORT = "common.contact_technical_support",

    NEXT_STEP = "next_step",
    PREVIOUS_STEP = "previous_step",
    CART = "cart",
    CART_IS_EMPTY = "cart.is_empty",
    CART_ITEMS_IN_CART = "cart.items_in_cart",
    CART_ITEM_AMOUNT = "cart.item.amount",
    CART_ITEM_DELETE = "cart.item.delete",
    CART_ITEM_PRICE = "cart.item.price",
    CART_ITEM_ORIGINAL_PRICE = "cart.item.original_price",
    CART_ITEM_DISCOUNTED_PRICE = "cart.item.discounted_price",
    CART_CUSTOMER_DETAILS = "cart.customer_details",
    CART_DELIVERY_METHODS_LOADING = "cart.delivery_methods.loading",
    CART_DELIVERY_METHODS_NOT_FOUND = "cart.delivery_methods.not_found",
    CART_DELIVERY_METHODS_TICKETS = "cart.delivery_methods.tickets",
    CART_DELIVERY_METHODS_MERCHANDISE = "cart.delivery_methods.merchandise",
    CART_DELIVERY_METHODS_SELECT = "cart.delivery_methods.select",
    CART_SEPARATE_DELIVERY_ADDRESS_LABEL = "cart.separate_delivery_address.label",
    CART_SEPARATE_DELIVERY_ADDRESS_TITLE = "cart.separate_delivery_address.title",
    CART_SEPARATE_DELIVERY_ADDRESS_DESCRIPTION = "cart.separate_delivery_address.description",
    CART_SEPARATE_DELIVERY_ADDRESS_MESSAGE = "cart.separate_delivery_address.message",
    CART_CODE_APPLY = "cart.code.apply",
    CART_CODE_APPLY_TITLE = "cart.code.apply.title",
    CART_CODE_APPLIED = "cart.code.applied",
    CART_CODES_USED = "cart.codes.used",
    CART_CODE_FIELD_TITLE = "cart.code_field_title",
    CART_CODE_FIELD_DESCRIPTION = "cart.code_field_description",

    CART_EXPIRES_IN = "cart.expires_in",
    CART_ACCEPT_TERMS = "cart.accept_terms",
    CART_OPEN_TERMS = "cart.open_terms",
    CART_THANK_YOU_TITLE = "cart.thank_you_title",
    CART_THANK_YOU_ORDER_NUMBER = "cart.thank_you_order_number",
    CART_ORDER_CONFIRMATION_TITLE = "cart.order_confirmation_title",
    CART_ORDER_CONFIRMATION_ORDER_NUMBER = "cart.order_confirmation_order_number",

    ORDER_SUMMARY = "order.summary",
    ORDER_TOTAL = "order.total",
    ORDER_TOTAL_ITEMS = "order.total.items",
    ORDER_TOTAL_DELIVERY = "order.total.delivery",
    ORDER_PROCEED_TO_CHECKOUT = "order.proceed_to_checkout",
    ORDER_PROCEED_TO_PAYMENT = "order.proceed_to_payment",
    ORDER_CONFIRM = "order.confirm",

    CHECKOUT_CANCELLED = "checkout.cancelled",
    ERRORS_OCCURRED = "errors.occurred",

    EVENT_REGISTER = "event.register",
    EVENT_REGISTRATION_ADDED_TO_CART = "event.registration_added_to_cart",
    EVENT_REGISTRATION_ADD_ANOTHER_PARTICIPANT = "event.registration_add_another_participant",
    EVENT_AMOUNT_VALIDATION_ERROR = "event.amount_validation_error",
    EVENT_CHECK_DETAILS = "event.check_details",

    PRODUCT_SERIALCARD_PACKAGE_PRICEINFO = "product.serialcard.package.priceinfo",
    PRODUCT_SERIALCARD_PACKAGE_DROPDOWN = "product.serialcard.package.dropdown",
}
