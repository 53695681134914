import React, {Fragment, useEffect, useState} from 'react';
import {Listbox, Transition} from '@headlessui/react'
import {CheckIcon, ChevronDownIcon} from '@heroicons/react/solid'
import {classNames} from "@eventiofi/eventio-commons-ui";
import {useTranslation} from "react-i18next";

import fiFlag from "../../images/flags/fi.png";
import svFlag from "../../images/flags/sv.png";
import ukFlag from "../../images/flags/uk.png";
import {useConfig} from "../../hooks/use-config.hook";
import {TranslationKeys} from "../../interfaces/translation-keys.enum";

const languages: {
    [key: string]: {
        title: string,
        file: any
    }
} = {
    "fi": {
        title: "Suomi",
        file: fiFlag
    },
    "en": {
        title: "English",
        file: ukFlag
    },
    "sv": {
        title: "Svenska",
        file: svFlag
    }
}

const LanguageSelector = (): JSX.Element => {
    const {t, i18n} = useTranslation();
    const config = useConfig();

    const [language, setLanguage] = useState(i18n.language);

    const handleChange = async (value: string) => {
        await i18n.changeLanguage(value);
    }

    useEffect(() => {
        setLanguage(i18n.language)
    }, [i18n.language])

    const buildAvailableLanguages = (): JSX.Element[] => {
        const elements: JSX.Element[] = [];

        for (const lang of config.languages.filter(lang => lang !== "de")) {
            const element = <Listbox.Option
                key={lang}
                className={({active}) =>
                    classNames(
                        active ? 'text-white bg-primary-600' : 'text-gray-900',
                        'cursor-default select-none relative p-2'
                    )
                }
                value={lang}
            >
                {({selected, active}) => (
                    <>
                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block')}>
                            {languages[lang].title}
                        </span>
                    </>
                )}
            </Listbox.Option>;

            elements.push(element)
        }

        return elements;
    }

    // Piilotetaan kielivalikko, jos vain yksi kieli valittavissa
    if(config.languages?.length === 1) {
        return <></>
    }

    return <Listbox value={language} onChange={handleChange}>
        {({open}) => (
            <>
                <Listbox.Label
                    className="block text-sm font-medium text-gray-700 sr-only">{t(TranslationKeys.COMMON_SELECT_LANGUAGE)}</Listbox.Label>
                <div className="relative">
                    <Listbox.Button className="btn btn-secondary flex items-center w-full">
                        <img
                            src={languages[language].file}
                            alt=""
                            className="h-6 block flex-shrink-0"
                        />
                        <span className="block text-sm font-medium ml-2 -mr-2"><ChevronDownIcon className="w-5 h-5"/></span>
                    </Listbox.Button>
                    <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Listbox.Options
                            className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm divide-y divide-gray-200">
                            {buildAvailableLanguages}
                        </Listbox.Options>
                    </Transition>
                </div>
            </>
        )}
    </Listbox>;
}

export default LanguageSelector;
