import React, { useEffect } from "react";
import Navigation from "./navigation/navigation.component";
import { useConfig } from "../hooks/use-config.hook";
import { Link } from "gatsby";
import poweredBy from "../images/powered-by-eventio_horizontal.svg";
import ErrorModal from "./error-modal.component";
import { useOverlayTriggerState } from "@react-stately/overlays";
import { clearCartError, clearCheckoutError } from "../store/actions/cart.action";
import ExpiryBanner from "./cart/expiry-banner.component";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "../interfaces/translation-keys.enum";
import { useChat } from "react-live-chat-loader";
import { useAppDispatch, useAppSelector } from "../hooks/use-redux.hook";

interface LayoutProps {
}

const Notification: React.FC = () => {

    const { t } = useTranslation();

    return (
        <div className="w-full bg-red-500 text-white flex flex-wrap justify-center items-center text-lg font-semibold">
            {t(TranslationKeys.COMMON_WEBSERVICE_IN_TEST_MODE)}
        </div>
    );
};

const Layout: React.FC<LayoutProps> = (props) => {
    const { children } = props;

    const config = useConfig();

    const dispatch = useAppDispatch();

    const cartErrorModal = useOverlayTriggerState({});
    const checkoutErrorModal = useOverlayTriggerState({});

    const cart = useAppSelector(state => state.cart);

    const { t } = useTranslation();

    const [state, loadChat] = useChat();

    useEffect(() => {
        if (cart.cart_error) {
            cartErrorModal.open();
        }
    }, [cart.cart_error, cartErrorModal]);

    useEffect(() => {
        if (cart.checkout_error) {
            checkoutErrorModal.open();
        }
    }, [cart.checkout_error, checkoutErrorModal]);

    if (cart.checkout_error) {
        loadChat({ open: true });
    }

    const closeCartErrorModal = () => {
        dispatch(clearCartError());
        cartErrorModal.close();
    };

    const closeCheckoutErrorModal = () => {
        dispatch(clearCheckoutError());
        checkoutErrorModal.close();
    };

    const styles: any = {};

    if (config.theme.colors.background) {
        styles.backgroundColor = config.theme.colors.background;
    }

    if (config.theme.bg_img) {
        styles.backgroundImage = "url(" + config.theme.bg_img + ")";
        // styles.backgroundRepeat = "no-repeat";
        styles.backgroundPosition = "center top";
    }

    return (
        <div className="min-h-full bg-gray-100" style={styles}>
            <ErrorModal
                closeModal={closeCartErrorModal}
                modalState={cartErrorModal}
                error={t(`errors.${cart.cart_error}` || "", cart.exception_parameters)}
            />
            <ErrorModal
                closeModal={closeCheckoutErrorModal}
                modalState={checkoutErrorModal}
                error={cart.checkout_error || ""}
            />
            {config.test_mode && <Notification />}
            <ExpiryBanner />
            <header>
                <img
                    src={config.theme.header_img}
                    alt=""
                    className="top-0 block mx-auto"
                />
            </header>
            <Navigation />
            <main className="container mx-auto sm:py-4 sm:px-6 lg:px-8 h-full">
                {children}
            </main>
            <footer className="bg-white">
                <div className="max-w-screen-xl mx-auto py-4 px-4 overflow-hidden sm:px-6 lg:px-8">
                    <nav className="-mx-5 -my-2 flex flex-wrap justify-center">
                        <div className="px-5 py-2">
                            <Link
                                to="/privacy-policy"
                                className="text-base leading-6 text-gray-500 hover:text-gray-900"
                            >
                                {t(TranslationKeys.COMMON_PRIVACY_POLICY)}
                            </Link>
                        </div>
                        <div className="px-5 py-2">
                            <Link
                                to="/cookie-policy"
                                className="text-base leading-6 text-gray-500 hover:text-gray-900"
                            >
                                {t(TranslationKeys.COMMON_COOKIE_POLICY)}
                            </Link>
                        </div>
                        {config.terms_and_conditions && (
                            <div className="px-5 py-2">
                                <Link
                                    to="/terms-and-conditions"
                                    className="text-base leading-6 text-gray-500 hover:text-gray-900"
                                >
                                    {t(TranslationKeys.COMMON_TERMS_AND_CONDITIONS)}
                                </Link>
                            </div>
                        )}
                        <div className="px-5 py-2">
                            <Link
                                to="/contact-us"
                                className="text-base leading-6 text-gray-500 hover:text-gray-900"
                            >
                                {t(TranslationKeys.COMMON_CONTACT)}
                            </Link>
                        </div>
                    </nav>
                    <div className="mt-8 w-full justify-center flex">
                        <a href="https://eventio.fi">
                            <img
                                src={poweredBy}
                                alt="Powered by Eventio"
                                className="h-10 self-center"
                            />
                        </a>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default Layout;
