import React, {useEffect} from "react";
import {StorageKeys} from "../../interfaces/storage-keys.enum";
import jwtDecode, {JwtPayload} from "jwt-decode";
import {TranslationKeys} from "../../interfaces/translation-keys.enum";
import {useTranslation} from "react-i18next";

interface ExpiryBannerProps {
}

const ExpiryBanner: React.FC<ExpiryBannerProps> = (props) => {
    const [expires, setExpires] = React.useState<number>(45);

    const [banner, setBanner] = React.useState(<></>);

    const {t} = useTranslation();

    useEffect(() => {
        const checkStamp = () => {
            const token = localStorage.getItem(StorageKeys.EVENTIO_CART_TOKEN);

            if (!token) {
                return;
            }
            const decoded = jwtDecode<JwtPayload>(token);
            if (!decoded.exp) {
                console.error("Token does not have expiry stamp");
                return;
            }
            const time = decoded.exp - Date.now().valueOf() / 1000;
            setExpires(Math.round(time / 60));
        };
        checkStamp();
        const interval = setInterval(checkStamp, 1000 * 60);
        return () => clearInterval(interval)
    }, []);

    useEffect(() => {
        if (expires > 5 && expires <= 10)
            setBanner(
                <div className="w-full p-4 text-center bg-gray-100 text-lg">
                    <p>{t(TranslationKeys.CART_EXPIRES_IN, {expires})}</p>
                </div>
            );

        if (expires > 1 && expires <= 5) {
            setBanner(
                <div className="w-full p-4 text-center bg-yellow-400 text-lg">
                    <p>{t(TranslationKeys.CART_EXPIRES_IN, {expires})}</p>
                </div>
            );
        }
        if (expires > 0 && expires <= 1) {
            setBanner(
                <div className="w-full p-4 text-center bg-red-500 text-white text-lg">
                    <p>{t(TranslationKeys.CART_EXPIRES_IN, {expires})}</p>
                </div>
            );
        }
    }, [expires]);

    return <>{banner}</>;
};

export default ExpiryBanner;
